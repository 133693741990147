<template>
  <div class="participant" :class="{skeleton: !state.loaded }">
    <div class="wrapper invest" v-if="modalParams.projectType === 'invest'">
      <div class="title font-md">
        <router-link :to="`/invest/${state.invest.project.investSeq}`">
          <b>{{ state.invest.project.projectName }}</b>
        </router-link>
      </div>
      <div class="content font-sm">
        <div class="wrapper">
          <div class="row">
            <div class="col-lg-6">
              <div class="subject">
                <span>프로젝트 정보</span>
              </div>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>
                    <span>회사명</span>
                  </th>
                  <td>
                    <span>{{ state.invest.project.corporateName }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>투자 종목</span>
                  </th>
                  <td>
                    <span>{{ state.invest.project.stockTypeText }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>목표 금액</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.invest.project.totAmount) }}</span>
                  </td>
                </tr>
                <tr>
                  <th><span>최소 투자 금액</span>
                  </th>
                  <td>
                  <span>
                    <span>{{ $lib.getNumberFormat(state.invest.project.minAmount) }}원</span>
                    <span>({{ $lib.getNumberFormat(state.invest.project.minCount) }}</span>
                    <span>{{ state.invest.project.stockTypeText === "주식" ? "주" : "좌" }})</span>
                  </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>펀딩 종료일</span>
                  </th>
                  <td>
                    <span>{{ state.invest.project.fundingEndDate }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>배정 예정일</span>
                  </th>
                  <td>
                    <span>{{ state.invest.project.distriDate }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6">
              <div class="subject">
                <span>투자 정보</span>
              </div>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>
                    <span>투자자</span>
                  </th>
                  <td>
                    <span>{{ state.invest.detail.invstrNm }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>투자일</span>
                  </th>
                  <td>
                    <span>{{ state.invest.detail.createDate }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>투자 금액</span>
                  </th>
                  <td>
                  <span>
                    <b>{{ $lib.getNumberFormat(state.invest.detail.subscrAmount) }}원</b>
                    <span>({{ $lib.getNumberFormat(state.invest.detail.subscrCount) }}</span>
                    <span>{{ state.invest.project.stockTypeText === "주식" ? "주" : "좌" }})</span>
                  </span>
                  </td>
                </tr>
                <tr v-if="state.project.stockStorageType !== '02' && state.invest.detail.invstrTrsacNo">
                  <th>
                    <span>증권 계좌</span>
                  </th>
                  <td>
                    <span v-if="state.invest.detail.invstrTrsacNo">
                      <span class="d-inline-block mr-1">{{ $definitions.codes.obj.securitiesCompany[state.invest.detail.secucoAcopno] }}</span>
                      <span class="d-inline-block mr-1">{{ state.invest.detail.invstrTrsacNo }}</span>
                      <span v-if="state.project.afterDistriDate === 'Y' && state.project.accountFlag === 'Y'">(정상계좌)</span>
                      <template v-else>
                        <br/>
                        <a class="d-inline-block pointer-u color-anchor" @click="editAccount()">계좌 수정</a>
                      </template>
                    </span>
                    <span v-else><a class="account" @click="editAccount()">계좌 등록하기</a></span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>상태</span>
                  </th>
                  <td>
                    <template v-if="state.project.regState === 'STATUS_0006'">
                      <template v-if="state.project.cancelFlag === 'Y'">
                        <span v-if="state.project.refundFlag === 'Y'">투자 취소 - 환불 대기 중</span>
                        <span v-else-if="state.project.refundFlag === 'N'">투자 취소 - 환불 완료</span>
                      </template>
                      <template v-else-if="state.project.cancelFlag === 'N' && state.project.endFlag === 'Y'">
                        <template v-if="state.project.successFailFlag === 'S'">
                          <template v-if="state.project.distriFlag === 'Y'">
                            <span v-if="state.project.distriCount === 0">펀딩 성공 - 초과 청약자로 배정 불가</span>
                            <template v-if="state.project.distriCount > 0">
                              <span v-if="state.project.distriCount === state.project.subscrCount">펀딩 성공 - 배정 완료</span>
                              <span v-if="state.project.distriCount !== state.project.subscrCount">펀딩 성공 - 초과 청약금 환불</span>
                            </template>
                          </template>
                          <span v-if="state.project.distriFlag === 'N'">펀딩 성공 - 배정 대기 중</span>
                        </template>
                        <span v-if="state.project.successFailFlag === 'F'">펀딩 실패</span>
                      </template>
                      <template v-else-if="state.project.cancelFlag === 'N' && state.project.endFlag === 'N'">
                        <span>진행 중</span>
                      </template>
                    </template>
                    <template v-else-if=" state.project.regState === 'STATUS_0007'">
                      <span>보류</span>
                    </template>
                    <template v-if="state.project.endFlag === 'N'">
                      <br/>
                      <span class="color-anchor pointer" @click="cancel()">투자 취소</span>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper reward" v-else>
      <div class="title font-md">
        <router-link :to="`/reward/${state.reward.detail.projectSeq}`">
          <b>{{ state.reward.detail.projectName }}</b>
        </router-link>
      </div>
      <div class="content font-sm">
        <div class="wrapper">
          <div class="row">
            <div class="col-lg-6">
              <div class="subject">
                <span>후원 내역</span>
                <span class="badge badge-secondary pointer font-xs" @click="changePayMethod()" v-if="computedChangeable">결제수단 변경</span>
              </div>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>
                    <span>후원 금액</span>
                  </th>
                  <td>
                    <span v-if="state.reward.detail.rewardCount > 0">{{ $lib.getNumberFormat(state.reward.detail.expenseAmt - state.reward.detail.addAmt - state.reward.detail.deliveryAmt) }}원</span>
                    <span v-else>{{ $lib.getNumberFormat(state.reward.detail.expenseAmt) }} (리워드 없이 참여)</span>
                  </td>
                </tr>
                <tr v-if="state.reward.detail.addAmt > 0">
                  <th>
                    <span>추가금액</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.reward.detail.addAmt) }}원</span>
                  </td>
                </tr>
                <tr v-if="state.reward.detail.omcpay > 0">
                  <th>
                    <span>포인트</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.reward.detail.omcpay) }}원</span>
                  </td>
                </tr>
                <tr v-if="state.reward.detail.couponPoint > 0">
                  <th>
                    <span>쿠폰</span>
                  </th>
                  <td>
                    <span>{{ $lib.getNumberFormat(state.reward.detail.couponPoint) }}원</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>결제금액</span>
                  </th>
                  <td>
                  <span>
                    <span>{{ $lib.getNumberFormat(computedPaidAmount) }}원</span>
                    <span v-if="(state.reward.detail.rewardCount > 0 || !state.loaded) && state.reward.detail.deliveryAmt > 0"> (배송비 {{ $lib.getNumberFormat(state.reward.detail.deliveryAmt) }}원 포함)</span>
                  </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>결제 정보</span>
                  </th>
                  <td>
                    <span>{{ getPayMethodTitle(state.reward.detail.paymethod) }}</span>
                    <span v-if="state.reward.detail.paymethod !== 'op_po' && state.reward.detail.paymethod !== 'omcpay' && state.reward.detail.omcpay > 0"> + 포인트</span>
                    <span v-if="state.reward.detail.incomingChk === '0'"> / 결제 완료</span>
                    <span v-else-if="state.reward.detail.incomingChk === '1'"> / 결제 대기</span>
                    <span v-else-if="state.reward.detail.incomingChk === '2'"> / 취소 완료</span>
                    <span> ({{ state.reward.detail.regDate }})</span>
                  </td>
                </tr>
                <tr v-if="state.reward.detail.fundingType === 'A' && state.reward.detail.scheduleState === '1'">
                  <th>
                    <span>결제예정</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.scheduleAt }}</span>
                  </td>
                </tr>
                <tr v-if="['3', '7'].includes(state.reward.detail.scheduleState)">
                  <th>
                    <span>실패사유</span>
                  </th>
                  <td>
                    <span class="color-danger">{{ state.reward.detail.scheduleNote }}</span>
                  </td>
                </tr>
                <tr class="virtual" v-if="state.reward.detail.paymethod === 'virtual'">
                  <th>
                    <span>가상계좌</span>
                  </th>
                  <td>
                  <span>
                    <span>{{ state.reward.detail.virtualCentercd }}</span>
                    <span> {{ state.reward.detail.rVirNo }}</span>
                  </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>송장번호</span>
                  </th>
                  <td>
                  <span v-if="state.reward.detail.deliveryCompany">
                    <span>{{ state.reward.detail.deliveryCompany }}</span>
                    <span v-if="state.reward.detail.trackingNumber">
                      {{ state.reward.detail.trackingNumber }}
                    </span>
                    <span class="pointer-u color-anchor ml-1" @click="getDeliveryState(state.reward.detail.deliveryCompanyCode, state.reward.detail.trackingNumber)">배송 조회</span>
                  </span>
                    <span v-else>(N/A)</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>응원 댓글</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.supportComment }}</span>
                    <span class="pointer-u color-anchor ml-1" @click="editCommentModel(state.reward.detail.investorSeq, state.reward.detail.supportComment)">수정</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 items">
              <div class="subject">
                <span>후원 상품 정보</span>
              </div>
              <div>
                <ul class="tight" v-if="state.reward.items.length">
                  <li v-for="(i, idx) in state.reward.items" :key="idx">
                    <div class="title">
                      <b v-html="i.title"></b>
                    </div>
                    <div class="count">
                      <span>- 리워드 수량: {{ i.cnt || 0 }}개</span>
                    </div>
                    <div class="option">
                      <span>- 리워드 금액: {{ $lib.getNumberFormat(i.investAmt) }}원</span>
                    </div>
                    <div class="expect">
                      <span>- 배송 예정일: {{ i.rewardExpectText }}</span>
                    </div>
                    <div class="option">
                      <span>- 리워드 정보: {{ $lib.isJsonString(i.rewardOption) ? $lib.getStringFromObj(JSON.parse(i.rewardOption)) : i.rewardOption || "(N/A)" }}</span>
                    </div>
                  </li>
                </ul>
                <div v-else>
                  <ul class="tight">
                    <li>
                      <div class="title">
                        <span v-if="Number(state.reward.detail.rewardCount) === 0">리워드 없이 참여하였습니다.</span>
                        <span v-else>데이터가 없습니다.</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="subject">
                <span>배송지 정보</span>
                <span class="badge badge-secondary pointer font-xs" @click="changeAddress()" v-if="state.reward.detail.progressOrder === '1'">수정</span>
              </div>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>
                    <span>이름</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.receiveName }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>연락처</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.hp }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="mr-1">주소</span>
                  </th>
                  <td>
                  <span>
                    <span v-if="state.reward.detail.postno">({{ state.reward.detail.postno }})</span>
                    <span> {{ state.reward.detail.addr1 }}</span>
                    <span> {{ state.reward.detail.addr2 }}</span>
                  </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>배송 메모</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.memo }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6">
              <div class="subject">
                <span>후원자 정보</span>
              </div>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>
                    <span>이름</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.senderName }}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>휴대전화</span>
                  </th>
                  <td>
                    <span>{{ state.reward.detail.senderHp }}</span>
                    <span v-if="state.reward.detail.investorHp"> (결제자 연락처: {{ state.reward.detail.investorHp }})</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="mr-1">주소</span>
                  </th>
                  <td>
                    <span v-if="state.reward.detail.senderPost && state.reward.detail.senderAddr1 && state.reward.detail.senderAddr2">
                      ({{ state.reward.detail.senderPost }}) {{ state.reward.detail.senderAddr1 }} {{ state.reward.detail.senderAddr2 }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="alert alert-info info" v-if="modalParams.projectType === 'reward'">
            <ol>
              <li>후원 취소는 펀딩 종료 1일 전까지 가능하며, 펀딩종료 당일 및 펀딩마감 이후에는 취소가 불가능합니다. 또한<!--펀딩이 마감일 전에 100% 이상 달성하는 경우와--> 펀딩 기간 중 리워드가 배송된 경우에도 취소가 불가능하오니 이 점 양해바랍니다.</li>
              <li v-if="state.reward.detail.progressOrder === '1'">배송지 정보 수정은 펀딩종료일까지 수정이 가능하며, <u class="pointer" @click="changeAddress()">수정</u> 버튼을 통해 가능합니다.</li>
              <li>가상계좌의 경우, 유효기간 내에 입금을 진행해주세요.</li>
              <li v-if="state.reward.detail.cancelOrder === '1' && ['0','1'].indexOf(state.reward.detail.incomingChk) > -1">
                프로젝트 후원 취소를 원하시는 경우, <u class="pointer" @click="cancel()">이 버튼</u>을 눌러주세요.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import Loading from "@/components/Loading";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalParticipant";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, Loading, NoData},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: modalParams.projectType === "invest" ? "md" : "lg"});
      load();
    });

    const state = reactive({
      loaded: false,
      project: {
        stockStorageType: "",
        endFlag: "",
        accountFlag: "",
        regState: "",
        cancelFlag: "",
        refundFlag: "",
        successFailFlag: "",
        distriFlag: "",
        distriCount: "",
        subscrCount: "",
        investorSeq: "",
        projectSeq: "",
        investSeq: "",
        subscribeSeq: ""
      },
      reward: {
        detail: {
          successFailFlag: "",
          projectSeq: "",
          projectName: "",
          fundingType: "",
          targetAmt: 0,
          progressOrder: "",
          cancelOrder: "",
          totalAmt: 0,
          regState: "",
          memberName: "",
          memberFilePath: "",
          expenseAmt: 0,
          addAmt: 0,
          omcpay: 0,
          rewardCount: 0,
          rewardAmt: 0,
          paymethod: "",
          paymethodText: "",
          investorSeq: "",
          incomingChk: 0,
          scheduleState: "",
          scheduleNote: "",
          regDate: "",
          trackingNumber: "",
          senderName: "Wait",
          senderHp: "010-0000-0000",
          senderEmail: "",
          senderPost: "Wait",
          senderAddr1: "Wait",
          senderAddr2: "a moment",
          receiveName: "Wait a moment",
          hp: "Wait a moment",
          investorHp: "010-0000-0000",
          memberSeq: "",
          email: "",
          postno: "Wait",
          addr1: "Wait a",
          addr2: "moment",
          memo: "Wait a moment",
          deliveryCompany: "",
          deliveryCompanyCode: "",
          deliveryAmt: 0,
          rVirNo: "",
          virtualCentercd: "",
          couponPoint: 0,
          couponId: "",
          deliveryYn: "",
          orderNo: "",
          scheduleAt: "",
          supportComment: "",
        },
        items: [],
      },
      invest: {
        project: {
          investSeq: "00",
          corporateName: "Wait a moment",
          projectName: "Wait a moment",
          stockTypeText: "Wait",
          thumbNailPath: "",
          fundingEndDate: "0000-00-00",
          distriDate: "0000-00-00",
          totAmount: 10000,
          minAmount: 10000,
          minCount: 1,
        },
        detail: {
          invstrNm: "Wait a moment",
          stockTypeText: "Wait",
          secucoAcopnoText: "Wait a moment",
          createDate: "0000-00-00",
          invstrTrsacNo: "",
          secucoAcopno: "",
        },
      },
    });

    // 배송정보 조회를 위한 택배사 코드 및 배송추적 링크
    const deliveryCompanyInfo = [
      ["0", "선택해주세요"]
      , ["COMP_01", "CJ 대한통운택배", {cpName: "CJ대한통운", url: "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=##NUM##"}]
      , ["COMP_04", "롯데택배", {cpName: "롯데", url: "https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=##NUM##"}]
      , ["COMP_05", "로젠택배", {cpName: "로젠", url: "https://www.ilogen.com/m/personal/trace/##NUM##"}]
      , ["COMP_03", "한진택배", {cpName: "한진", url: "https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnum=##NUM##"}]
      , ["COMP_02", "우체국택배", {cpName: "우체국", url: "https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?displayHeader=N&sid1=##NUM##"}]
      , ["COMP_08", "CU 편의점택배", {cpName: "CU", url: "https://www.cupost.co.kr/postbox/delivery/localResult.cupost?invoice_no=##NUM##"}]
      , ["COMP_07", "편의점택배(GS)", {cpName: "편의점택배(GS)", url: "https://www.cvsnet.co.kr/invoice/tracking.do?invoice_no=##NUM##"}]
      , ["COMP_10", "경동택배", {cpName: "경동", url: "https://kdexp.com/basicNewDelivery.kd?barcode=##NUM##"}]
      , ["COMP_11", "대신택배", {cpName: "대신", url: "https://www.ds3211.co.kr/freight/internalFreightSearch.ht?billno=##NUM##"}]
      , ["COMP_27", "범한판토스", {cpName: "범한판토스", url: "http://www.epantos.com/jsp/gx/tracking/tracking/trackingInquery.jsp?refNo=##NUM##"}]
      , ["COMP_12", "일양로지스택배", {cpName: "일양로지스", url: "http://www.ilyanglogis.com/functionality/tracking_result.asp?hawb_no=##NUM##"}]
      , ["COMP_16", "천일택배", {cpName: "천일", url: "http://www.chunil.co.kr/HTrace/HTrace.jsp?transNo=##NUM##"}]
      , ["COMP_17", "한의사랑택배", {cpName: "한의사랑", url: "http://www.hanips.com/html/sub03_03_1.html?logicnum=##NUM##"}]
      , ["COMP_33", "호남택배", {cpName: "호남", url: "http://honamlogis.co.kr/page/?pid=tracking_number&SLIP_BARCD=##NUM##"}]
      , ["COMP_20", "DHL택배", {cpName: "DHL", url: "http://www.dhl.co.kr/content/kr/ko/express/tracking.shtml?brand=DHL&AWB=##NUM##"}]
      , ["COMP_19", "EMS", {cpName: "EMS", url: "http://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=##NUM##"}]
      , ["COMP_23", "FedEx택배", {cpName: "FEDEX", url: "http://www.fedex.com/Tracking?ascend_header=1&clienttype=dotcomreg&cntry_code=kr&language=korean&tracknumbers=##NUM##"}]
      , ["COMP_14", "GTX로지스택배", {cpName: "GTX로지스", url: "http://www.gtxlogis.co.kr/tracking/default.asp?awblno=##NUM##"}]
      , ["COMP_36", "KGB택배", {cpName: "KGB", url: "http://www.kgbls.co.kr/sub5/trace.asp?f_slipno=##NUM##"}]
      , ["COMP_37", "OCS택배", {cpName: "OCS", url: "http://www.ocskorea.com/online_bl_multi.asp?mode=search&search_no=##NUM##"}]
      , ["COMP_21", "TNT Express", {cpName: "TNTExpress", url: "http://www.tnt.com/webtracker/tracking.do?respCountry=kr&respLang=ko&searchType=CON&cons=##NUM##"}]
      , ["COMP_22", "UPS택배", {cpName: "UPS", url: "http://wwwapps.ups.com/WebTracking/track?track=yes&loc=ko_kr&trackNums=##NUM##"}]
      , ["COMP_34", "GSI Express", {cpName: "GSI", url: "http://www.gsiexpress.com/track_pop.php?track_type=ship_num&query_num=##NUM##"}]
      , ["COMP_38", "SLX", {cpName: "SLX", url: "http://slx.co.kr/delivery/delivery_number.php?param1=##NUM##"}]
      , ["COMP_35", "대한항공", {cpName: "대한항공", url: "http://cargo.koreanair.com/ecus/trc/servlet/TrackingServlet?pid=5&version=kor&menu1=m1&menu2=m01-1&awb_no=##NUM##"}]
      , ["COMP_39", "농협택배", {cpName: "농협택배", url: "https://ex.nhlogis.co.kr/dlvy/dlvy/view.do"}]
    ];

    const modalParams = store.getters.modalParams(component);

    const computedPaidAmount = computed(() => {
      return lib.getNumbered(state.reward.detail.expenseAmt, true)
          - lib.getNumbered(state.reward.detail.omcpay, true)
          - lib.getNumbered(state.reward.detail.couponPoint, true);
    });

    const getPayMethodTitle = (name) => {
      switch (name) {
        case "naverpay":
          return "네이버페이";

        case "virtual":
          return "가상계좌";

        case "card":
          return "카드";

        case "real":
          return "실시간 이체";

        case "omcpay":
          return "포인트";

        case "point":
          return "쿠폰";

        case "op_po":
          return "포인트 + 쿠폰";
      }
    };

    const computedChangeable = computed(() => {
      if (state.reward.detail.cancelOrder === "1") {
        return ["0", "1"].includes(state.reward.detail.incomingChk) &&
            state.reward.detail.scheduleState === "1" && state.reward.detail.paymethod === "naverpay";
      }

      return state.reward.detail.paymethod === "naverpay" && state.reward.detail.scheduleState === "3";
    });

    // const computedDitriDate = computed(() => {
    //   if (!state.project.distriDate || state.project.distriDate.length !== 8) {
    //     return "";
    //   }
    //
    //   const year = window.Number(state.project.distriDate.substring(0, 4));
    //   const month = window.Number(state.project.distriDate.substring(4, 6)) - 1;
    //   const date = window.Number(state.project.distriDate.substring(6, 8));
    //   const distributeDate = new Date(year, month, date);
    //
    //   distributeDate.setDate(distributeDate.getDate() - 1);
    //   console.log(distributeDate < new Date());
    // });

    const changePayMethod = () => {
      const requestPay = () => {
        window.IMP.request_pay({
          pg: "naverpay",
          name: lib.getSafeJsString(state.reward.detail.projectName),
          amount: state.reward.detail.expenseAmt - (state.reward.detail.omcpay + state.reward.detail.couponPoint),
          customer_uid: state.reward.detail.orderNo.replaceAll("R", ""),
          naverProductCode: state.reward.detail.orderNo.replaceAll("R", ""),
          naverActionType: "CHANGE",
          buyer_name: state.reward.detail.senderName,
          buyer_tel: state.reward.detail.investorHp,
          naverPopupMode: false,
          app_scheme: "",
          m_redirect_url: `${location.origin}/callback/changePay?redirectUrl=/mypage/participant/reward`
        }, res => {
          !res.success && store.commit("setSwingMessage", res.error_msg || definitions.messages.errorCommon);
        });
      };

      return store.dispatch("appendScript", {
        src: "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js",
        onFirstLoad() {
          window.IMP.init(definitions.imp.initId);
        },
        onEveryLoad: requestPay
      });
    };

    const changeAddress = () => {
      store.commit("openModal", {
        name: "RewardAddress",
        params: {
          detail: {
            investorSeq: state.reward.detail.investorSeq,
            addr1: state.reward.detail.addr1,
            addr2: state.reward.detail.addr2,
            postno: state.reward.detail.postno,
            receiveName: state.reward.detail.receiveName,
            hp: state.reward.detail.hp,
            memo: state.reward.detail.memo
          }
        },
        callback: `${component.name}.load`
      });
    };

    const cancel = async () => {
      if (modalParams.projectType === "invest") {
        if ((!await store.getters.investProjectAvailable("myPage"))) {
          return store.commit("openModal", {
            name: "InvestProjectPauseNotice",
            routerReplace: true,
            preventClose: true,
            params: {
              keep: true
            }
          });
        }

        store.commit("confirm", {
          message: `프로젝트(${state.project.projectName})의 투자를 취소하시겠습니까? 취소하시면 복구할 수는 없으나 다시 청약하실 수 있습니다.`,
          allow() {
            http.put(`/api/participant/invest/projects/${state.project.investorSeq}/cancel?subscribeSeq=${state.project.subscribeSeq}`).then(() => {
              store.commit("closeModal", {
                name: component.name,
                onClose(modal) {
                  store.dispatch("callback", {modal});
                  store.commit("setSwingMessage", "해당 프로젝트의 투자를 취소하였습니다.");
                }
              });
            }).catch(httpError());
          }
        });
      } else {
        store.commit("confirm", {
          message: "이 프로젝트 참여를 취소하시겠습니까?",
          allow() {
            const args = {
              investorSeq: state.project.investorSeq,
              memberSeq: store.state.account.memberSeq,
              requester: "customer"
            };

            http.put(`/api/reward/project/${state.project.projectSeq}/payments/cancel`, args).then(() => {
              store.commit("closeModal", {
                name: component.name,
                onClose(modal) {
                  store.dispatch("callback", {modal});
                  store.commit("setSwingMessage", "프로젝트 참여를 취소하였습니다.");
                }
              });
            }).catch(httpError());
          }
        });
      }
    };

    const editAccount = () => {
      store.commit("openModal", {
        name: "InvestorAccount",
        params: {
          project: {
            secucoAcopno: state.project.secucoAcopno,
            invstrTrsacNo: state.project.invstrTrsacNo,
            accountDate: state.project.accountDate,
            subscribeSeq: state.project.subscribeSeq,
          }
        },
        callback: `${component.name}.load`
      });
    };

    const getDeliveryState = (companyCode, trackingNo) => {
      if (companyCode.length > 0) {
        trackingNo = trackingNo.replaceAll("-", "");
        let url = "";
        const deliveryCompanyInfoArray = deliveryCompanyInfo;
        deliveryCompanyInfoArray.forEach((ele, idx) => {
          if (companyCode === ele[0]) {
            url = deliveryCompanyInfoArray[idx][2].url;
          }
        });

        if (url.match(/##NUM##/)) {
          store.dispatch("popup", {
            url: url.replace(/##NUM##/, trackingNo),
            target: "_blank"
          });
        } else {
          store.dispatch("popup", {
            url: url,
            target: "_blank"
          });
        }
      } else {
        store.commit("setSwingMessage", "송장번호나 택배사이름이 등록되어 있지 않습니다");
      }
    };

    const putComment = ({investorSeq, content}) => {
      http.put("/api/reward/projects/support-comment", {investorSeq, content}).then(() => {
        store.commit("setSwingMessage", "응원 댓글을 수정하였습니다.");
        load();
      }).catch(httpError());
    };

    const editCommentModel = (investorSeq, content) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          investorSeq: investorSeq,
          content: content
        },
        callback: `${component.name}.putComment`,
      });
    };

    const load = async () => {
      state.loaded = false;
      const res1 = await http.get(`/api/participant/${modalParams.projectType}/projects/${modalParams.detailSeq}`).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));
      state.loaded = true;

      if (res1?.error) {
        store.commit("closeModal", {name: component.name});
        return;
      }

      state.project = res1.data.body;

      if (modalParams.projectType === "invest") {
        state.loaded = false;
        const res2 = await http.get(`/api/invest/project/${state.project.investSeq}/subscribe/${state.project.subscribeSeq}`).catch(httpError(() => {
          store.commit("closeModal", {name: component.name});
        }));
        state.loaded = true;

        if (res2?.error) {
          store.commit("closeModal", {name: component.name});
          return;
        }

        state.invest.project = res2.data.body.project;
        state.invest.detail = res2.data.body.subscribe;
      } else {
        state.reward.items = [{
          title: "Wait a moment",
          rewardExpectText: "Wait a moment",
          investAmt: 1000,
          rewardOption: "Wait a moment"
        }];

        // 후원자 정보 상세
        state.loaded = false;
        const res2 = await http.get(`/api/participant/reward/projects/${state.project.investorSeq}/detail`).catch(httpError(() => {
          store.commit("closeModal", {name: component.name});
        }));
        state.loaded = true;

        // 후원자 정보 리워드 목록 상세
        state.loaded = false;
        const res3 = await http.get(`/api/participant/reward/projects/${state.project.investorSeq}/reward-items`).catch(httpError(() => {
          store.commit("closeModal", {name: component.name});
        }));
        state.loaded = true;

        if (res2?.error || res3?.error) {
          store.commit("closeModal", {name: component.name});
          return;
        }

        state.reward.detail = res2.data.body;
        state.reward.items = res3.data.body;

        state.reward.detail.omcpay = state.reward.detail.omcpay || 0;
        state.reward.detail.couponPoint = state.reward.detail.couponPoint || 0;
      }
    };

    return {component, state, modalParams, computedPaidAmount, getPayMethodTitle, computedChangeable, changePayMethod, changeAddress, cancel, editAccount, getDeliveryState, putComment, editCommentModel, load};
  }
});
</script>

<style lang="scss" scoped>
.participant {
  > .wrapper {
    background: #fff;
    padding: $px25;

    > .title {
      padding-bottom: $px25;

      span {
        color: #767676;
        margin-left: $px2;
      }
    }

    .content {
      > .wrapper {
        .row {
          > div {
            .subject {
              padding-bottom: $px9;

              span {
                &.badge {
                  float: right;
                  margin-left: $px5;
                }
              }
            }

            table {
              table-layout: fixed;

              tr {
                th, td {
                  border-width: $px1;

                  span {
                    vertical-align: top;
                  }
                }

                th {
                  background: $colorBackground;
                }

                td {
                  > span {
                    word-break: break-all;
                  }
                }
              }
            }

            &.items {
              ul {
                li {
                  background: $colorBackground;
                  border: $px1 solid #eee;
                  margin-bottom: $px15;
                  padding: $px15;

                  .count {
                    padding-top: $px10;
                  }

                  .expect {
                    padding-top: $px5
                  }

                  .option {
                    padding-top: $px5;
                  }
                }
              }
            }
          }
        }

        .alert.info {
          padding: $px20 $px20 $px2 0;
          margin-top: $px10;
          margin-bottom: 0;

          ol {
            padding-left: $px35;

            > li {
              u {
                font-weight: 500;
              }

              &:not(:last-child) {
                margin-bottom: $px5;
              }
            }
          }
        }
      }
    }

    &.invest {
      .row > div table th {
        width: $px115;
      }
    }

    &.reward {
      .row {
        padding: $px15 0;

        > div table th {
          width: $px85;
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .wrapper .content > .wrapper .row > div:not(:last-child) {
      margin-bottom: $px25;
    }
  }

  &.skeleton {
    > .wrapper {
      > .title > a > b {
        @include skeleton;
      }

      .content {
        > .wrapper {
          .row > div {
            .subject > span {
              @include skeleton;
            }

            table {
              th, td {
                > span {
                  @include skeleton;

                  a {
                    visibility: hidden;
                  }
                }
              }
            }

            &.items {
              ul li > div {
                > b, > span {
                  @include skeleton;
                }
              }
            }
          }

          .alert {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>